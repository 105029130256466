import React, { createContext, useState } from "react";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [lang, setLang] = useState(sessionStorage.getItem("lang") || "hu");

  const updateLang = (newLang) => {
    sessionStorage.setItem("lang", newLang);
    setLang(newLang);
  };

  return (
    <LanguageContext.Provider value={{ lang, updateLang }}>
      {children}
    </LanguageContext.Provider>
  );
};