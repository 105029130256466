import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { Container } from "react-bootstrap";
import { useContext } from "react";
import { LanguageContext } from "./components/LanguageContext";


const SearchPage = () => {
    const { id } = useParams();
    const [searchResults, setSearchResults] = useState([]);
    const [searchResultTraining,setSearchResultTraining]= useState([])
    const [searchTrainer,setSearchTraniner]=useState([])
    const { lang } = useContext(LanguageContext);

    const [token,setToken]=useState('')

const getToken = async()=>{
    axios.defaults.withCredentials = true;
    const response = await axios.get(`https://api.kegon.hu/sanctum/csrf-cookie`,{
    })
    return response
}

useEffect(()=>{
    getToken('').then((response)=>{
        setToken(response)
        // .config.xsrfCookieName
    })
},[])


    useEffect( () => {
        axios.defaults.withCredentials = true;
     axios.get(`https://api.kegon.hu/sanctum/csrf-cookie`,{
        }).then(
      axios .post(`https://api.kegon.hu/${lang}/api/search`, {

                expression: id,
            })
            .then((res) => {
                setSearchResults(res.data.content)
                setSearchResultTraining(res.data.training)
                setSearchTraniner(res.data)
               console.log(res.data.content,"kozol");
               console.log(res.data,'trainer')
            })
            );

        document.title = "Kegon  " + id + " keresése";
    },[]);




    return (

        <div>
        { searchResults.length > 0 || searchResultTraining.length > 0 ? (
            <Container>
            <div className="searchResults">
                <h1> {lang === "hu" ? ` Keresési találatok "${id}" kifejezésre`  : `Search results for "${id}"`}</h1>


                {searchResults && searchResults.map((item, index) => {
                    return (
                        <div key={index} className="searchResult">
                            <h3>{item.name}</h3>
                            <br></br>
                            <p>{item.text}</p>
                            <Link
                                to={
                                    item.prefix != null
                                        ? "/" + item.link
                                        : "/" + item.link
                                }
                            >
                                {item.link === null && <div className="d-flex align-items-center"> <Link to={"/"}/> Home </div>}
                                {item.name}
                            </Link>
                        </div>
                    );
                })}

{searchResultTraining && searchResultTraining.map((item, index) => {
                    return (
                        <div key={index} className="searchResult">
                            <h3>{item.name}</h3>
                            <br></br>
                            <p>{item.text}</p>
                            <Link
                                to={
                                    "/training/" + item.link
                                }
                            >
                                {item.link === null && <div className="d-flex align-items-center"> <Link to={"/"}/> Home </div>}
                                {item.name}
                            </Link>
                        </div>
                    );
                })}


            </div>
            </Container>
        ) : (
            <Container>
            <div className="searchResults">
                <h1>{lang === "hu" ? ` Nincs találat a "${id}" keresésedre...`  : `No results for your search "${id}"...`}</h1>


            </div>
            </Container>
        )}


            </div>





     );
}

export default SearchPage;
