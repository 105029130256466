import Module0 from "./components/Modules/Module0";
import Module1 from './components/Modules/Content/Module1';
import Module2 from './components/Modules/Module2';
import Module3 from './components/Modules/Module3';
import Module4 from './components/Modules/Module4';
import Module5 from './components/Modules/Module5';
import Module6 from './components/Modules/Module6';
import Module7 from './components/Modules/Module7';
import Module8 from './components/Modules/Module8';
import Module9 from './components/Modules/Module9';
import Module10 from './components/Modules/Module10';
import Module11 from './components/Modules/Module11';
import Module12 from './components/Modules/Module12';
import GetData from './services/api';
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DocumentMeta from 'react-document-meta';
import { LanguageContext } from './components/LanguageContext';
import {StickyShareButtons} from 'sharethis-reactjs';

const Content = () => {
    const [moduleToLoad, setModuleToLoad] = useState([])
    const [contentToLoad, setContentToLoad] = useState([])
    const [metaData, setMetaData] = useState('')
    const [title, setTitle] = useState('')
    const { id } = useParams()
    const location = useLocation();
    const { lang } = useContext(LanguageContext);

    useEffect(() => {
        GetData(`content/${id}`, lang).then((response) => {
            setContentToLoad(response.data.content)
            setModuleToLoad(response.data.modules)
            setMetaData(response.data.content)
        })
    }, [location,lang,id])

    useEffect(() => {
        GetData('config', lang).then((response) => {
            setTitle(response.data.title_hu)
        })
    }, [location,lang])

    document.title = title + " - " + metaData.title

    const meta = {
        meta: {
            charset: 'utf-8',
            name: {
                keywords: metaData.keywords,
                description: metaData.description
            }
        }
    }

    const moduleMap = {
        1: Module1,
        2: Module2,
        3: Module3,
        4: Module4,
        5: Module5,
        6: Module6,
        7: Module7,
        8: Module8,
        9: Module9,
        10: Module10,
        11: Module11,
        12: Module12
    }


    return (
        <div>
            <DocumentMeta {...meta} />
            <Module0 contentToLoad={contentToLoad} />

            <StickyShareButtons
          config={{
            alignment: 'right',    // alignment of buttons (left, right)
            color: 'social',      // set the color of buttons (social, white)
            enabled: true,        // show/hide buttons (true, false)
            font_size: 16,        // font size for the buttons
            hide_desktop: false,  // hide buttons on desktop (true, false)
            labels: 'counts',     // button labels (cta, counts, null)
            language: 'en',       // which language to use (see LANGUAGES)
            min_count: 0,         // hide react counts less than min_count (INTEGER)
            networks: [           // which networks to include (see SHARING NETWORKS)
              'linkedin',
              'facebook',
              'twitter',
              'pinterest',
              'email'
            ],
            padding: 12,          // padding within buttons (INTEGER)
            radius: 4,            // the corner radius on each button (INTEGER)
            show_total: true,     // show/hide the total share count (true, false)
            show_mobile: false,    // show/hide the buttons on mobile (true, false)
            show_toggle: true,    // show/hide the toggle buttons (true, false)
            size: 48,             // the size of each button (INTEGER)
            top: 160,             // offset in pixels from the top of the page
 
            // OPTIONAL PARAMETERS
            url: `https://kegon.hu/${id}`, // (defaults to current url)
            image: `${contentToLoad.image}`,  // (defaults to og:image or twitter:image)
            description: `${contentToLoad.description}`,       // (defaults to og:description or twitter:description)
            title: `${contentToLoad.title}`,            // (defaults to og:title or twitter:title)
            message: `${contentToLoad.title}`,     // (only for email sharing)
            subject: 'custom email subject',  // (only for email sharing)
            username: 'custom twitter handle' // (only for twitter sharing)
 
          }}
        />
        
            {moduleToLoad && moduleToLoad.map((module) => {
                const ModuleComponent = moduleMap[module.module];
                return ModuleComponent && <ModuleComponent key={module.module} moduleData={module.data} />;
            })}
        </div>
    );
}

export default Content;