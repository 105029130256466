import { Container, Row } from "react-bootstrap";
import { useContext } from "react";
import { LanguageContext } from "../LanguageContext";

const Module8 = ({ moduleData }) => {
    
const { lang } = useContext(LanguageContext);

    return (
        <div className="about-container" style={{ backgroundImage: 'url(../Rectangle_182.png)', backgroundSize:'cover' }}>
            <Container className="position-relative">
                <Row>
                    <div data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom">
                        {moduleData &&
                            <p className="display-2 fw-bold">{moduleData[0].name}</p>
                        }
                    </div>
                </Row>
                <Row className="d-flex">

                    {moduleData &&
                        <div className="col-lg-9 fs-4 pt-4 " >
                            <div data-aos="fade-up"
                                data-aos-anchor-placement="top-bottom" className="col-lg-10 fs-5 pt-4" dangerouslySetInnerHTML={{ __html: moduleData[0].text }}></div>

                        </div>}
                    <div className="col-lg-3 d-flex align-items-end justify-content-start">
                        <img style={{width:'150px'}} className="  " src="../Group.png" alt="" />
                    </div>
                </Row>
                <Row>

                    <div className="col-lg-3 pt-5">
                        <a href={lang === "en" ? "/calendar"  : "/naptar"}>
                        <button className="button-book" data-aos="fade-up"
                            data-aos-anchor-placement="top-bottom">
                                
                            {lang === "en" ? "BOOK NOW"  : "FOGLALJ MOST"}
                            </button>
                            </a>
                    </div>
                </Row>
                <Row>

                </Row>
            </Container>
        </div>
    );
}

export default Module8;
