import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "./LanguageContext";
import { useContext } from "react";

const NotFound = () => {
    const { lang } = useContext(LanguageContext);
    const navigate = useNavigate();
    useEffect(() => {
        document.title = "404 - Az oldal nem található";
    }, []);

    return (



        <div className="notFoundContainer">



            <h1>404</h1>
            <h2>{lang === "en" ? "The page you are looking for cannot be found"  : "A keresett oldal nem található"}</h2>
            <button onClick={() => navigate("/")} className="button-book">
            {lang === "en" ? "Back to the home page"  : "Vissza a kezdőlapra"}
            </button>
        </div>
    );
};

export default NotFound;
