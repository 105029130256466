import { Container, Row, Col } from "react-bootstrap";
import React, { useState, useEffect, useContext } from "react";
import getData from "../services/api";
import axios from "axios";
import { LanguageContext } from "./LanguageContext";
const Contactus = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [position, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [isAccepted, setIsAccepted] = useState(false);
  const [contactData, setContactData] = useState("");
  const [firstNameIsInvalid, setFirstNameIsInvalid] = useState(false);
  const [lastNameIsInvalid, setLastNameIsInvalid] = useState(false);
  const [companyNameIsInvalid, setCompanyNameIsInvalid] = useState(false);
  const [positionIsInvalid, setPositionIsInvalid] = useState(false);
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [phoneIsInvalid, setPhoneIsInvalid] = useState(false);
  const [messageIsInvalid, setMessageIsInvalid] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const { lang, updateLang } = useContext(LanguageContext);
  const [error, setError] = useState("");
  const handleChange = (event) => {
    setIsAccepted(event.target.checked);
  };

  useEffect(() => {
    getData("config", lang).then((response) => {
      setContactData(response.data);
    });
  }, [lang]);

  const emailRegex = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const isValid = () => {
    if (firstName.length < 2) {
      setFirstNameIsInvalid(true);
    } else {
      setFirstNameIsInvalid(false);
    }
    if (lastName.length < 2) {
      setLastNameIsInvalid(true);
    } else {
      setLastNameIsInvalid(false);
    }
    if (companyName.length < 2) {
      setCompanyNameIsInvalid(true);
    } else {
      setCompanyNameIsInvalid(false);
    }
    if (position.length < 2) {
      setPositionIsInvalid(true);
    } else {
      setPositionIsInvalid(false);
    }
    if (phone.length < 6) {
      setPhoneIsInvalid(true);
    } else {
      setPhoneIsInvalid(false);
    }

    if (!emailRegex.test(email)) {
      setEmailIsInvalid(true);
    } else {
      setEmailIsInvalid(false);
    }
    if (message.length < 2 || message.length > 100) {
      setMessageIsInvalid(true);
    } else {
      setMessageIsInvalid(false);
    }
    if (
      firstName.length > 1 &&
      lastName.length > 1 &&
      emailRegex.test(email) &&
      message.length < 101
    ) {
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    axios.defaults.withCredentials = true;
    if (isValid()) {
      window.gtag("event", "conversion", {
        send_to: "AW-11139665393/9Ld9CLfGsJYYEPGb578p"
      });
      axios
        .get(`https://api.kegon.hu/sanctum/csrf-cookie`, {})
        .then(() =>
          axios.post("https://api.kegon.hu/api/message", {
            lastname: lastName,
            firstname: firstName,
            companyName: companyName,
            position: position,
            email: email,
            phone: phone,
            message: message,
            privacy: isAccepted,
            terms: isAccepted,
            coomercial: isAccepted,
          })
        )
        .then((res) => {
          setFirstName("");
          setLastName("");
          setEmail("");
          setPhone("");
          setMessage("");
          setCompanyName("");
          setPosition("");
          setFirstNameIsInvalid(false);
          setLastNameIsInvalid(false);
          setEmailIsInvalid(false);
          setPositionIsInvalid(false);
          setMessageIsInvalid(false);
          setIsAccepted(false);
          setCompanyNameIsInvalid(false);
          setPositionIsInvalid(false);

          setSubmitSuccessful(true);
          setSubmitFailed(false);
          setTimeout(() => {
            setSubmitSuccessful(false);
          }, 3000);
        })
        .catch((error) => {
          setError(error.response.status);
          setSubmitFailed(true);
        });
    } else {
      setSubmitFailed(true);
    }
  };

  return (
    <div
      className="contactus-container"
      style={{
        backgroundImage: "url(../contactus.png)",
        backgroundSize: "cover",
      }}
    >
      <Container>
        <Row>
          <div
            className="col-lg-3 pb-5"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            <p className="display-5 fw-normal">
              {" "}
              {lang === "en" ? "Contact us" : "Kapcsolat"}{" "}
            </p>
          </div>
          <Col
            className="col-lg-4"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            <p className="fs-5">
              {lang === "en"
                ? "Feel free to drop us an email anytime.We'd love to hear from you"
                : "Nyugodtan írj nekünk egy e-mailt bármikor. Szívesen hallanánk Rólad."}
            </p>
          </Col>
        </Row>
      </Container>
      {submitSuccessful ? (
    <div className="alert alert-success">
      {lang === "en"
        ? "Your message has been sent!"
        : "Az üzenetét elküldtük!"}
    </div>
  ) : (
    error && (
      <div className="alert alert-danger">
        {lang === "en"
          ? "Something went wrong"
          : "Valami hiba történt"}
      </div>
    )
  )}
      <Container>
        <Row>
          <Col>
            <div
              className="row g-3"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              <div className="col-lg-4">
                {lastNameIsInvalid && (
                  <small style={{ color: "red" }}>
                    {lang === "en"
                      ? "*At least two characters"
                      : "*Legalább két karaktert"}
                  </small>
                )}
                <input
                  type="text"
                  className="form-control border-bottom rounded-0 p-0 text-white pb-3 "
                  placeholder={lang === "en" ? "Last Name*" : "Vezetéknév*"}
                  aria-label="Last name"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />
              </div>
              <div className="col-lg-4">
                {firstNameIsInvalid && (
                  <small style={{ color: "red" }}>
                    {lang === "en"
                      ? "*At least two characters"
                      : "*Legalább két karaktert"}
                  </small>
                )}
                <input
                  type="text"
                  className="form-control border-bottom rounded-0 p-0 text-white pb-3"
                  placeholder={lang === "en" ? "First Name*" : "Keresztnév*"}
                  aria-label="First name"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            <div
              className="row g-3"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              <div className="col-lg-4 pt-3">
                {companyNameIsInvalid && (
                  <small style={{ color: "red" }}>
                    {lang === "en"
                      ? "*At least two characters"
                      : "*Legalább két karaktert"}
                  </small>
                )}
                <input
                  type="text"
                  className="form-control border-bottom rounded-0 p-0 text-white pb-3"
                  placeholder={lang === "en" ? "Company Name*" : "Cégnév*"}
                  aria-label="Company name"
                  value={companyName}
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                />
              </div>
              <div className="col-lg-4 pt-3">
                {/* {positionIsInvalid && (
                  <small style={{ color: "red" }}>
                    {lang === "en"
                      ? "*At least two characters"
                      : "*Legalább két karaktert"}
                  </small>
                )} */}
                <input
                  type="text"
                  className="form-control border-bottom rounded-0 p-0 text-white pb-3"
                  placeholder={lang === "en" ? "Position" : "Pozíció"}
                  aria-label="Position"
                  value={position}
                  onChange={(e) => {
                    setPosition(e.target.value);
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            <div
              className="row g-3"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              <div className="col-lg-4 pt-3">
                {emailIsInvalid && (
                  <small style={{ color: "red" }}>
                    {lang === "en"
                      ? "*Wrong e-mail format"
                      : "*Rossz e-mail formátum"}
                  </small>
                )}
                <input
                  type="email"
                  className="form-control border-bottom rounded-0 p-0 text-white pb-3"
                  placeholder={lang === "en" ? "E-mail*" : "E-mail*"}
                  aria-label="E-mail*"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="col-lg-4 pt-3">
                {phoneIsInvalid && (
                  <small style={{ color: "red" }}>
                    {lang === "en"
                      ? "*Minimum 6 maximum 20 number"
                      : "*Minimum 6 maximum 20 szám"}
                  </small>
                )}
                <input
                  type="number"
                  className="form-control border-bottom rounded-0 p-0 text-white pb-3"
                  placeholder={lang === "en" ? "Phone number*" : "Telefonszám*"}
                  aria-label="Phone number*"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* <Container>
    <Row>
        <Col className="col-lg-12">

            <div className="col-lg-8 pt-2">
            <input type="text" className="form-control border-bottom rounded-0 p-0 text-white" placeholder="Message" aria-label="Message"></input>
            </div>
        </Col>
        <Col className="col-lg-3">
        <div className="test">
        <input type="checkbox" value="val1" name="group1" className="test"></input>
        <p>I understand and i accept the Privacy policy</p></div>
        </Col>
    </Row>

</Container> */}

      <Container>
        <Row
          className="d-flex flex-wrap "
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
        >
          <div className="col-lg-8">
            {messageIsInvalid && (
              <small style={{ color: "red" }}>
                {lang === "en"
                  ? "*At least 2, maximum 100 character"
                  : "*Legalább 2 maximum 100 karakter"}
              </small>
            )}
            <textarea
              type="text"
              className="form-control border-bottom rounded-0 p-0 text-white pb-3 mt-2 "
              rows={3}
              placeholder={lang === "en" ? "Message" : "Üzenet"}
              aria-label="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>

          <div className="contact-us-understand  col-lg-4 mt-3">
            <div className="d-flex flex-row align-items-start">
              <input
                type="checkbox"
                value="1"
                name="group1"
                className="contact-us-input mt-2 me-3 "
                onChange={handleChange}
                onClick={(e) => setIsAccepted(e.target.checked)}
              />

              <p className="">
                {lang === "en"
                  ? "I understand and i accept the "
                  : "Megértem és elfogadom az "}
                <a className="ps-1" target="_blank" href={lang === "en" ? "https://api.kegon.hu/uploads/files/Adatkezelési tájékoztató_230322_ENG.pdf"  : "https://api.kegon.hu/uploads/files/Adatkezelési tájékoztató_230322_HU.pdf"}>
                  {lang === "en"
                    ? "Privacy notice"
                    : "Adatkezelési tájékoztatót "}
                </a>{" "}
              </p>
            </div>
            <div className="d-flex flex-row align-items-start">
              <input
                type="checkbox"
                value="1"
                name="group1"
                className="mt-2 me-3"
                onChange={handleChange}
                onClick={(e) => setIsAccepted(e.target.checked)}
              />
              <p className="">
                {" "}
                {lang === "en"
                  ? "I understand and i accept the "
                  : "Megértem és elfogadom az "}
                <a className="ps-1" target="_blank" href={lang === "en" ? "https://api.kegon.hu/uploads/files/aszf_en.pdf"  : "https://api.kegon.hu/uploads/files/aszf_hu.pdf"}>
                  {lang === "en"
                    ? "Terms and Conditions"
                    : "Általános Szerződési Feltételeket "}{" "}
                </a>{" "}
              </p>
            </div>
            <div className="d-flex flex-row align-items-start">
              <input
                type="checkbox"
                value="1"
                name="group1"
                className="mt-2 me-3"
                onChange={handleChange}
                onClick={(e) => setIsAccepted(e.target.checked)}
              />
              <p className="d-flex align-items-end">
                {lang === "en"
                  ? "I accept to get Commercial Offers"
                  : "Elfogadom, hogy kereskedelmi ajánlatokat kapjak"}
              </p>
            </div>
          </div>
        </Row>
      </Container>

      {/* <Container>
    <Row>
        <Col>
        <div className="test">
        <input type="checkbox" value="val1" name="group1" className="test"></input>
        <p>I understand and i accept the Privacy policy</p></div>
        </Col>
    </Row>
    <Row>
        <Col>
        <input type="checkbox" value="val1" name="group1" className="test"></input>
       <p> I understand and i accept the  Terms and Conditions </p>
        </Col>
    </Row>
    <Row>
        <Col>
        <input type="checkbox" value="val1" name="group1" className="test"></input>
        <p>I accept to get Commercial Offers</p>
        </Col>
    </Row>
</Container> */}
      <Container>
        <Row>
          <div
            className="col-lg-4 pt-2 mt-3"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            <button className="button-book2" onClick={() => handleSubmit()}>
              {lang === "en" ? "SEND" : "KÜLDÉS"}
            </button>

          </div>
        </Row>

      </Container>
    </div>
  );
};

export default Contactus;
