import { useState,useEffect,useContext } from "react";
import { Container,Row } from "react-bootstrap";
import Navbar from 'react-bootstrap/Navbar';
import getData from '../services/api';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { LanguageContext } from "./LanguageContext";
const Footer = () => {

    const [footer,setFooter] = useState('')
    const location = useLocation();
    const { lang } = useContext(LanguageContext);

    useEffect(() => {
        getData('navigation',lang).then((response) => {

            setFooter(response.data.nav2)
            // console.log(response.data.nav2)
            window.scrollTo(0, 0)
        })
    }, [location,lang])


    return (
        <div className="footer-container">
            <Container className="text-uppercase">
                <Row>
                <div className="footer-content-main d-flex flex-wrap col-lg-12">
                    <div className="col-lg-3" data-aos="fade-up"
        data-aos-anchor-placement="top-bottom">
                          <Navbar.Brand className='d-flex me-0 pt-2 pb-2 ' href="/" >
                          <img src="../Kegon_Logo_weiss-rgb 1.png" alt="" />

                </Navbar.Brand>

                    </div>
<div className="footer-content d-flex col-lg-8   justify-content-between text-center">
                    {footer && footer.map((nav) => (
                                    <div className='col-lg-2   pt-2 text-center ps-2' key={nav.name} data-aos="fade-up"
                                    data-aos-anchor-placement="top-bottom">
                                        <Link to={nav.url} className='nav-hover  pt-2 text-center' key={nav.name}>{nav.name}</Link>
                                        {nav.children && nav.children.map((child) => (
                                            <div key={child.name}>
                                                <div className="pt-2">
                                                <Link to={child.url} className='nav-hover pt-2' key={child.name}>{child.name}</Link>
                                            </div>
                                            </div>
                                        ))}

                                    </div>
                                ))}
                                <div className="col-lg-4 d-flex flex-column pt-2 align-items-between" data-aos="fade-up"
                                    data-aos-anchor-placement="top-bottom">
                                 <a className="ps-1" target="_blank" href={lang === "en" ? "https://api.kegon.hu/uploads/files/aszf_en.pdf"  : "https://api.kegon.hu/uploads/files/aszf_hu.pdf"}>
                  {lang === "en"
                    ? "Terms and Conditions"
                    : "Általános Szerződési Feltételek"}
                </a>{" "}
                <a className="ps-1 pt-2" target="_blank" href={lang === "en" ? "https://api.kegon.hu/uploads/files/Adatkezelési tájékoztató_230322_ENG.pdf"  : "https://api.kegon.hu/uploads/files/Adatkezelési tájékoztató_230322_HU.pdf"}>
                  {lang === "en"
                    ? "Privacy notice"
                    : "Adatkezelési tájékoztató "}{" "}
                </a>{" "}
                </div>
                  </div>
                    {/* <Col className="col-lg-3 d-flex flex-column mt-2 " data-aos="fade-up"
        data-aos-anchor-placement="top-bottom">
                        <a className="" href="" >training courses</a>

                        <Row>
                            <Col className="pt-3"><a href="">about us</a></Col>
                        </Row>
                        <Row>
                            <Col className="pt-3">
                                <a href="">Contact us</a>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pt-3 pb-4">
                                <a href="">Impressum</a>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="col-lg-4 d-flex flex-column mt-2" data-aos="fade-up"
        data-aos-anchor-placement="top-bottom">
                        <a href="">Általános szerződési feltételek</a>
                        <Row>
                            <Col className="pt-3">
                                <a href=""> Adatkezelési tájékoztató</a>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pt-3">
                                <a href="">Adatvédelmi tájékoztató</a>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pt-3">
                                <a href="">Adatvédelmi szabályzat</a>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pt-3 pb-4">
                                <a href="">Cookie szabályzat</a>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="col-lg-2 d-flex flex-column mt-2" data-aos="fade-up"
        data-aos-anchor-placement="top-bottom">
                        <a href="">Facebook</a>

                        <Row>
                            <Col className="pt-3">
                                <a href="">Lindekin</a>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pt-3">
                                <a href="">Instagram</a>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pt-3 pb-4">
                                <a href="">Contacts</a>
                            </Col>
                        </Row>
                    </Col> */}
                </div>
                </Row>
            </Container>
        </div>
    );
}

export default Footer;
