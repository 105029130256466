import { Container, Row, Col } from "react-bootstrap";
import { LanguageContext } from "../LanguageContext";
import { useContext } from "react";

const Module3 = ({ moduleData }) => {

    const number = Math.floor(Math.random() * 1)
    const { lang } = useContext(LanguageContext);
    return (
        <div className="protips-container" >
            {moduleData &&


                <Container className="d-flex flex-wrap  position-relative">
                    <img className="propicture" src="./thr.png" alt="" data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom" />
                    <Row className="d-flex flex-wrap ">
                        <Col className="col-lg-9 d-flex">
                            <div className="d-flex flex-wrap ">
                                <div className="col-lg-10 display-2 fw-bold me-5 d-flex align-items-end" data-aos="fade-up"
                                    data-aos-anchor-placement="top-bottom">
                                        {lang === "en" ? `Pro' tipp from Csaba`  : `Pro' tippek Csabától`} 
                                        </div>
                                <div className="col-lg-9 d-flex fs-5" data-aos="fade-up"
                                    data-aos-anchor-placement="top-bottom" dangerouslySetInnerHTML={{ __html: moduleData[number].text }} >
                                    </div>
                            </div>

                        </Col>
                        <div className="col-lg-3 d-flex align-items-center justify-content-center"><img style={{maxHeight:'250px'}} className="h-75 rounded-circle" src={`${moduleData[0].image}`} alt="" data-aos="fade-up"
                            data-aos-anchor-placement="top-bottom" /></div>
                            {/* src={`${moduleData[0].image}`} */}

                    </Row>


                </Container>
            }
        </div>
    );
}

export default Module3;