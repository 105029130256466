import { Container, Row, Col } from "react-bootstrap";
import React, { useContext,useState } from "react";
import { LanguageContext } from "../LanguageContext";


const Module12 = ({ moduleData }) => {
    const { lang } = useContext(LanguageContext);
    const [isVisible, setIsVisible] = useState(false);

    const handleClick = () => {
        setIsVisible(true);
    }

    return (
        <div>
            <Container>
                <Row data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom">
                      
                      
                    <Col className=""> <p className="display-2 fw-bold pt-5 pb-5">
                    {lang === "en" ? "Upcoming courses"  : "Jelenlegi képzések"} 
                        
                        </p></Col>
                </Row>

            </Container>

            <Container className="text-uppercase fw-bold d-flex flex-wrap  " >

                {moduleData && moduleData.map((upcomings) => (

                    <Container className="up-1 col-lg-6 mt-4  mb-5" data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom" key={upcomings.name}>

                        <div className="col-lg-12">
                            <div className=" flip-card">
                                <div className=" flip-card-inner">
                                    <div className="flip-card-front" >
                                        <div className="upcoming3-box container-lg p-4  d-grid shadow p-3 mb-5 rounded " style={{ backgroundImage: `url(${upcomings.trainers[0].image})`, backgroundSize:'cover', backgroundPosition: 'center', height: '558px', maxWidth: '700px', }}>
                                            <img style={{ maxWidth: '100px' }} src={upcomings.image} alt="" />
                                            
                                            <p className="fs-5  d-flex align-items-end  justify-content-center"  >{upcomings.name}</p>
                                        
                                        </div>
                                    </div>
                                    <div className="flip-card-back" onMouseOver={()=>{setIsVisible(!isVisible)}}  >
                                        <div className=" upcoming2-box container-lg p-4 shadow p-3 mb-5 rounded" style={{ maxWidth: '558px', height: '558px' }}>

                                            <p className="yellow fs-5 pt-5">{upcomings.name}</p>
                                            <p className="fs-5 mt-5 text-white">{upcomings.language}</p>
                                            <div>
                                                <div className="fs-5 mt-5 text-white">{upcomings.trainers && upcomings.trainers.map((trainer) => (
                                                    <p key={trainer.name}>{trainer.name}</p>
                                                ))}</div>
                                            </div>
                                            <p className="yellow fs-5 mt-5 ">{upcomings.start.substring(0, 10)}</p>
                                            <a href={upcomings.link}><button className="button-book mt-5">{lang === "en" ? "BOOK NOW"  : "FOGLALJ MOST"}</button></a>

                                        </div>
                                    </div>
                                </div>
                            </div></div>
                    </Container>

                ))
                }
            </Container>
            <Container className="d-flex container-lg bg-white mt-5 ">
                <Row className="">

                    <div className="col-lg-6">
                        <div className="our-calendar" data-aos="fade-up"
                            data-aos-anchor-placement="center-bottom">
                            <p className="display-2 fw-bolder">
                            {lang === "en" ? "Let's check our training calendar"  : "Nézze meg tréning kalendárunkat"} 
                                </p>
                        </div>

                    </div>
                    <div className="col-lg-6 d-flex align-items-end " data-aos="fade-up"
                        data-aos-anchor-placement="center-bottom">
                            {lang === "en" ? "Learning should be something that you're intrinsically motivated to do, rather than just a response to the constantly changing world, which forces you to constantly try to keep up with the latest technology. We'd like to show you how valuable it is to exchange ideas and experiment."  :
                            "A tanulásnak olyasvalaminek kell lennie, amire önmaga motiválja, és nem csupán válasznak a folyamatosan változó világra, amely arra kényszeríti, hogy folyamatosan próbáljon lépést tartani a legújabb technológiával. Szeretnénk megmutatni, hogy mennyire értékes az eszmecsere és a kísérletezés."}
                        <p className="fs-5">  </p>

                    </div>


                </Row>
            </Container>
            <Container className="mb-4">
                <Row>

                    <div className=" col-lg-12 text-end" data-aos="fade-up"
                        data-aos-anchor-placement="center-bottom">
                        <a className="text-decoration-none" href={lang === "en" ? "/calendar"  : "/naptar"}> <button className="button-book mb-5">
                        {lang === "en" ? "GO TO CALENDAR"  : "NAPTÁR"} 
                            </button></a>
                    </div>
                </Row>
            </Container>

        </div>
    );
}

export default Module12;